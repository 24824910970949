<template>
  <div class="fixed top-0 left-0 z-30 w-full">
    <div
      ref="AnnouncementMarquee"
      v-if="announcement_list.length > 0"
      class="relative z-20 w-full h-8 py-1 overflow-hidden text-sm font-medium text-center text-black bg-primary"
    >
      <p
        v-for="(item, item_index) in announcement_list"
        :key="`announcement_${item_index}`"
        :class="announcement_list.length == 1 ? 'top-0' : 'top-full'"
        class="absolute left-0 flex items-center justify-center w-full h-full overflow-hidden whitespace-nowrap"
      >
        <span v-if="item.link == ''">
          {{ item.content }}
        </span>
        <a
          v-else
          :href="item.link"
          target="_blank"
          :key="`announcement_${item_index}`"
          class="flex items-center justify-center w-full h-full overflow-hidden whitespace-nowrap"
        >
          {{ item.content }}
        </a>
      </p>
    </div>
    <div class="relative">
      <div
        class="relative md:bg-transparent bg-black z-10 w-full h-[70px] md:h-[100px]"
      >
        <div
          class="relative z-10 flex items-center justify-between w-full h-full px-5 mx-auto max-w-screen-2xl 2xl:px-0"
        >
          <button
            @click="$emit('menu-action')"
            class="block h-full text-white transition-colors duration-300 md:hidden hover:md:text-primary"
          >
            <i v-show="!menu_status" class="icon-menu"></i>
            <i v-show="menu_status" class="icon-close"></i>
          </button>
          <!-- <router-link
            to="/"
            class="absolute z-10 flex items-center h-full transform -translate-x-1/2 -translate-y-1/2 lg:translate-x-0 lg:translate-y-0 lg:relative lg:top-auto lg:left-0 left-1/2 top-1/2"
          >
            <img src="/img/logo@lg.svg" class="block w-32" />
          </router-link> -->
          <router-link
            to="/"
            @click.native="CloseMenu"
            class="absolute block transform -translate-x-1/2 -translate-y-1/2 md:relative md:top-0 md:left-0 md:translate-x-0 md:translate-y-0 top-1/2 left-1/2"
          >
            <img
              width="180"
              height="40"
              :alt="$GetColumn('brand_name')"
              src="/img/logo@lg.svg"
              class="relative lg:w-[200px] md:w-[150px] w-[120px]"
            />
          </router-link>

          <div
            class="flex items-center md:py-5 md:px-4 rounded-xl md:bg-primary"
          >
            <ol class="items-center hidden h-full mr-5 md:flex">
              <li
                v-for="(item, item_index) in menu_list"
                :key="`list_item_${item_index}`"
                class="flex items-center h-full"
              >
                <router-link
                  class="flex items-center h-full px-2 text-xs font-bold transition-colors duration-300 lg:px-3 lg:text-sm hover:md:opacity-60"
                  :to="item.link"
                >
                  {{ item.title }}
                </router-link>
              </li>
            </ol>
            <ol class="flex items-center h-full text-white md:text-black">
              <li class="hidden h-full md:block">
                <button
                  @click="$router.push('/account/login')"
                  class="flex items-center justify-center w-6 h-full px-5 transition-colors duration-300 hover:md:opacity-60"
                >
                  <i class="icon-user"></i>
                </button>
              </li>
              <li class="h-full">
                <button
                  @click="OpenSearchDialog"
                  class="flex items-center justify-center w-6 h-full px-5 transition-colors duration-300 hover:md:opacity-60"
                >
                  <i class="icon-magnifying-glass"></i>
                </button>
              </li>
              <li class="h-full">
                <button
                  @click="$store.commit('SetShopCartStatus', true)"
                  class="relative flex items-center justify-center h-full px-2 transition-colors duration-300 hover:md:opacity-60"
                >
                  <div class="relative w-6 h-5">
                    <span
                      class="absolute -right-2 z-10 px-1 text-xs font-bold h-4 min-w-[16px] block transform rounded-full -top-1 md:bg-black bg-primary text-black md:text-primary scale-[.9]"
                      >{{ shop_cart_count }}</span
                    >
                    <i class="relative z-0 icon-cart-shopping"></i>
                  </div>
                </button>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div
        :class="shop_cart_dialog ? 'translate-y-full' : ''"
        class="absolute bottom-0 left-0 right-0 z-0 block font-bold text-black transition-all duration-300 transform bg-primary md:hidden"
      >
        <div
          class="w-full z-10 max-w-[1440px] px-5 mx-auto xl:px-0 sm:px-10 py-4 relative flex items-center md:justify-center justify-between"
        >
          已將商品加入您的購物車！
          <span
            @click="OpenShopCart"
            class="block ml-2 text-xs font-medium underline cursor-pointer"
            >查看購物車</span
          >

          <button
            @click="CloseShopCartDialog"
            class="absolute hidden text-xl transform -translate-y-1/2 md:block xl:right-0 sm:right-10 right-5 top-1/2"
          >
            <i class="icon-close"></i>
          </button>
        </div>
        <div
          class="absolute top-0 left-0 right-0 bottom-0 transform origin-left bg-white bg-opacity-20 ease-linear transition-all duration-[5s]"
          :class="shop_cart_dialog ? 'scale-x-100' : 'scale-x-0'"
        ></div>
      </div>

      <div
        class="fixed top-[100px] pointer-events-none left-1/2 transform md:flex hidden justify-end -translate-x-1/2 w-full max-w-screen-2xl 2xl:px-0 px-5 z-30 overflow-x-hidden"
      >
        <div class="flex justify-end w-full overflow-hidden">
          <div
            :class="shop_cart_dialog ? '-translate-x-0' : 'translate-x-full'"
            class="relative z-0 overflow-hidden text-white transition-all duration-300 transform bg-black border rounded-lg bg-opacity-10 w-72 border-primary"
          >
            <div
              class="relative z-10 flex items-start justify-between w-full px-5 py-4 md:justify-center"
            >
              <div class="mr-5">
                已將商品加入您的購物車！
                <span
                  @click="OpenShopCart"
                  class="block text-xs font-medium underline cursor-pointer pointer-events-auto text-primary"
                  >查看購物車</span
                >
              </div>

              <button
                @click="CloseShopCartDialog"
                class="text-xl pointer-events-auto text-primary md:block"
              >
                <i class="icon-close"></i>
              </button>
            </div>
            <div
              class="absolute top-0 left-0 right-0 bottom-0 z-0 transform origin-left ease-linear transition-all duration-[5s] bg-black"
              :class="shop_cart_dialog ? 'scale-x-100' : 'scale-x-0'"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AnnouncementMarquee } from "@/gsap/AnnouncementMarquee";
export default {
  name: "MainHeader",
  data() {
    return {
      menu_list: [
        {
          title: "商品列表",
          sub_title: "Products",
          link: "/collections",
        },
        {
          title: "關於我們",
          sub_title: "About Us",
          link: "/about",
        },
        {
          title: "最新消息",
          sub_title: "News",
          link: "/news",
        },
        {
          title: "經銷列表",
          sub_title: "Dealers",
          link: "/findus",
        },
        {
          title: "客服中心",
          sub_title: "Support",
          link: "/support",
        },
      ],
      drop_down: false,
      dialog_timer: null,
      announcement_gsap: null,
    };
  },
  props: {
    menu_status: {
      require: true,
      type: Boolean,
    },
  },
  methods: {
    OpenShopCart() {
      this.$store.commit("SetShopCartStatus", true);
    },
    CloseShopCartDialog() {
      this.$store.commit("SetShopCartDialog", false);
    },
    OpenSearchDialog() {
      this.$store.commit("SetSearchDialog", true);
    },
  },
  computed: {
    shop_cart_dialog() {
      return this.$store.state.shop_cart_dialog;
    },
    shop_cart_data() {
      return this.$store.state.shopcart_module.shopcart;
    },
    shop_cart_count() {
      let count = 0;
      this.shop_cart_data.forEach((item) => {
        count += parseInt(item.amount);
      });
      return count;
    },
    announcement_list() {
      return this.$store.getters.announcement_list;
    },
  },
  watch: {
    shop_cart_dialog() {
      if (this.shop_cart_dialog) {
        this.dialog_timer != null ? clearTimeout(this.dialog_timer) : "";
        this.dialog_timer = setTimeout(() => {
          this.CloseShopCartDialog();
        }, 5000);
      }
    },
  },
  mounted() {
    if (this.announcement_list.length > 1) {
      this.announcement_gsap = new AnnouncementMarquee(
        this.$refs.AnnouncementMarquee
      );
      this.announcement_gsap.setup();
    }
  },
};
</script>
